import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import PostAbbrev from "components/PostAbbrev";
import { getSlug } from "utils/helpers";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import "./index.css";

function IndexPage({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Gagandeep Rangi" description="Research based and curiosity driven articles." />
      <blockquote style={{ fontSize: `17px`, paddingLeft: `10px` }}>
        Collecting Timeless Ideas.
      </blockquote>

      <div className="all-posts">
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <PostAbbrev
              base="/"
              key={node.fields.slug}
              type={node.frontmatter.contentType}
              slug={getSlug(node.fields.slug)}
              date={node.frontmatter.date}
              timeToRead={node.timeToRead}
              cover={node.frontmatter.cover}
              title={title}
              excerpt={node.frontmatter.description || node.excerpt}
              tags={node.frontmatter.tags}
              description={node.frontmatter.description}
            />
          );
        })}
      </div>
    </Layout>
  );
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

IndexPage.defaultProps = {};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            contentType
            cover {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

