import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import "./postabbrev.css";
import Image from "gatsby-image";

function PostAbbrev({ slug, title, excerpt, cover, type }) {
  let excerptPart;
  if (excerpt) {
    excerptPart = (
      <p
        style={{ fontSize: "16px" }}
        dangerouslySetInnerHTML={{
          __html: excerpt
        }}
      />
    );
  }

  return (
    <Link style={{ boxShadow: "none" }} to={slug} rel="bookmark">
      <article className="post-article">
        {cover && (
          <div className="post-image">
            {type === "text" && <div className="post-type-text" title="Text post" />}
            {type === "video" && <div className="post-type-video" title="Video post" />}
            <Image
              fluid={cover.childImageSharp.fluid}
              alt="Featured Image"
              imgStyle={{
                borderRadius: `0%`
              }}
              className="post-image"
            />
          </div>
        )}

        <header className="post-header">
          <h3 className="post-h3">
            {/* {type === "text" && <div className="post-type-text" title="Text post" />}
            {type === "video" && <div className="post-type-video" title="Video post" />} */}
            <Link style={{ boxShadow: "none" }} to={slug} rel="bookmark">
              {title}
            </Link>
          </h3>
          <span className="post-desc">{excerptPart}</span>
        </header>
        <div style={{ clear: `both` }} />
      </article>
    </Link>
  );
}

PostAbbrev.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  cover: PropTypes.object,
  type: PropTypes.string
};

PostAbbrev.defaultProps = {
  title: null,
  excerpt: null,
  cover: null,
  type: ""
};

export default PostAbbrev;

